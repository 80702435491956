import axios from "axios";
let axiosInstance = window.axiosInstance;
import config, { base_url , end_points } from "../config";
import { wrapJWT } from "../Plugins/JWT.helper";
import { userDataStore } from "../Plugins/UserData.Store"

const uploadFormData = async (url, formData, token, requestId) => {
    try {
        let uploadData;
        if (token) {
            let config = {
                headers: {
                    "authorization" : token,
                    "temptoken": true,
                    "requestId": requestId
                }
            }
            uploadData = await axios.post(url, formData, config);
        } else {
            let config = {};
            try {
                let jwtToken = await wrapJWT(userDataStore.getters.userData.token);
                config = {
                    headers: {
                        "authorization" : jwtToken
                    }
                }
            } catch(err) {
                console.log("Error while wrapping jwt", err);
            }
            uploadData = await axios.post(url, formData, config);
        }
        if (uploadData.data && uploadData.data.fileUrl) {
            return uploadData.data.fileUrl;
        } else {
            return "";
        }
    } catch (err) {
        console.log("Problem in uploading the file", err);
        return "";
    }
}

const uploadJSONBody = async (url, jsonBody, token, requestId) => {
    try {
        let uploadData;
        if (token) {
            let config = {
                headers: {
                    "authorization": token,
                    "temptoken": true,
                    "requestId": requestId
                } 
            }
            uploadData = await axios.post(url, jsonBody, config);
        } else {
            let config = {};
            try {
                config = {
                    headers: {
                        "authorization" : await wrapJWT(userDataStore.getters.userData.token)
                    }
                }
            } catch(err) {
                console.log("Error while wrapping jwt", err);
            }
            uploadData = await axios.post(url, jsonBody, config);
        }
        // console.log("UploadData", uploadData.data);
        if (uploadData.data && uploadData.data.fileUrl){
            return uploadData.data.fileUrl;
        } else {
            return "";
        }

    } catch (error) {
        console.log("Problme in uploading the file", error);
        return "";
    }
}

const downloadFile = async (url) => {
    try {
        let userId = userDataStore.getters.userData.userId || "";
        const imageData = await axiosInstance.get(end_points.download_files(url, userId));
        return imageData;
    } catch (error) {
        console.log("Error in downloading the file", error);
        return "";
    }
}

export {
    uploadFormData,
    uploadJSONBody,
    downloadFile
}