//check isEmpty
let isEmpty = (data) => {
    if (data == undefined || data == "")
        return true;
    else
        return false;
}

function padDoubleDigits(number) {
    if (number >= 0)
        return number < 10 ? `0${number}` : number.toString();
    else
        return -number < 10 ? `-0${-number}` : number.toString();
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

function radians(deg) { return deg * Math.PI / 180; };

function distributeAngles(me, total) {
    return me / total * 360;
}

function rgb(r, g, b) {

    if (g == undefined) g = r;
    if (b == undefined) b = r;
    return 'rgb(' + clamp(Math.round(r), 0, 255) + ', ' + clamp(Math.round(g), 0, 255) + ', ' + clamp(Math.round(b), 0, 255) + ')';

}

function clamp(value, min, max) {
    return Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));
}

function map(value, min1, max1, min2, max2, clampResult) {
    var returnvalue = ((value - min1) / (max1 - min1) * (max2 - min2)) + min2;
    if (clampResult) {
        return clamp(returnvalue, min2, max2);
    } else { return returnvalue; };
}

let getPosition = function (options) {
    return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
};

let convertToRinger = (seconds) => {
    let mins = Math.floor(seconds / 60);
    let secs = seconds - mins * 60;
    return `${
        mins.toString().length == 1 ? "0" + mins : mins
        }:${secs.toString().length == 1 ? "0" + secs : secs}`;
}

let rightClickBlock = (e) => {
    e.preventDefault();
}

let disableInspect = (e) => {
    if (e.keyCode == 123) {
        return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
        return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
        return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
        return false;
    }
    if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
        return false;
    }
}

// window.getRandomSignature = getRandomSignature;
export {
    isEmpty,
    padDoubleDigits,
    getRandomInt,
    radians,
    distributeAngles,
    rgb,
    map,
    rightClickBlock,
    disableInspect,
    getPosition, convertToRinger
};